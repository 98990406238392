// const apibaseUrl = `https://mdqualityapps.in/API/shopztrend/UAT/`;

// Live Server
const apibaseUrl =  `https://shopztrend.com/ProdApiFolder/`;



const methodGet = 'GET';
const methodPost = 'POST';

const add_inbound = `${apibaseUrl}add_inbound`;

const add_outbound = `${apibaseUrl}add_outbound`;

const get_inbound = `${apibaseUrl}get_inbound`;

const get_outbound = `${apibaseUrl}get_outbound`;

const get_excel_outbound = `${apibaseUrl}get_excel_outbound`;

const excel_outbound = `${apibaseUrl}excel_outbound`;

const login = `${apibaseUrl}admin_signin`;

const getuser = `${apibaseUrl}getuser`;

const ProfileImageAPI = `${apibaseUrl}userprofile/`;

const get_crm_category_name = `${apibaseUrl}get_crm_category_name`;

const get_provinces= `${apibaseUrl}get_provinces`;

const get_crm_industry_name= `${apibaseUrl}get_crm_industry_name`;

const get_crm_person_type = `${apibaseUrl}get_crm_person_type`;

const get_crm_call_type= `${apibaseUrl}get_crm_call_type`;

const update_outbound= `${apibaseUrl}update_outbound`;

const get_crm_company_name= `${apibaseUrl}get_crm_company_name`;

const update_outbound_follow_up_remarks= `${apibaseUrl}update_outbound_follow_up_remarks`;

const update_inbound= `${apibaseUrl}update_inbound`;

export { methodGet,get_provinces ,update_inbound , update_outbound_follow_up_remarks , get_crm_company_name ,update_outbound ,excel_outbound , get_crm_category_name , get_crm_industry_name, get_crm_person_type , get_crm_call_type ,get_excel_outbound, methodPost,getuser,ProfileImageAPI, add_inbound,add_outbound, get_inbound, get_outbound, login }